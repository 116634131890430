import React from 'react'



const Footer = () => {

    return <div className='footer'>
        <h5> &copy; 2024 jonassundt.no</h5>
    </div>
}

export default Footer